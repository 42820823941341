import { useRootStore } from '@nandosaus/state-management';
import { get, isEmpty } from 'lodash';

import { useState, useEffect } from 'react';
import { useBrazeUpsellContent } from '../use-braze-upsell-content';

const useUpsellModalHandler = ({ modalOpeningDelay } = {}) => {
  const { CartStore, DietaryPreferencesStore, MenuStore } = useRootStore();
  const { menu } = MenuStore;
  const { upsellModalShown, updateUpsellModalShown } = CartStore;

  const menuRecommendedProducts = get(menu, 'recommendedProducts', []);
  const { recommendedProducts } = useBrazeUpsellContent({ heading: '', cta: '' });
  const chosenDietaryPreferences = get(DietaryPreferencesStore, 'dietaryPreferencesList');

  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);
  const [upsellModalTriggered, setUpsellModalTriggered] = useState(false);

  useEffect(() => {
    const timer = upsellModalTriggered ? setTimeout(() => setIsUpsellModalOpen(true), modalOpeningDelay || 0) : null;
    return () => {
      if (upsellModalTriggered) clearTimeout(timer);
    };
  }, [upsellModalTriggered, modalOpeningDelay]);

  const closeUpsellModal = () => {
    setIsUpsellModalOpen(false);
    updateUpsellModalShown();
  };

  const openUpsellModal = () => {
    if (CartStore.itemCount > 0) {
      setUpsellModalTriggered(true);
    }
  };

  /*
   * Open the upsell modal if:
   * 1. there are products to show
   * 2. no dietary preferences are set
   * 3. not already been shown to the user
   */
  const isUpsellModalEnabled =
    (!isEmpty(recommendedProducts) || !isEmpty(menuRecommendedProducts)) && isEmpty(chosenDietaryPreferences);

  return {
    isUpsellModalEnabled,
    isUpsellModalOpen,
    hasUpsellModalShown: upsellModalShown,
    closeUpsellModal,
    openUpsellModal,
  };
};

export { useUpsellModalHandler };
