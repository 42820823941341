import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { useRootStore } from '@nandosaus/state-management';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import styled from 'styled-components';

import { analytics } from '@analytics';
import { Button } from '@components/button';
import { DefaultState } from '@components/default';
import { ErrorBoundary } from '@components/error-boundary';
import { Box, Flex } from '@components/grid';
import { GroupedItemList } from '@components/grouped-item-list';
import { Icon } from '@components/icons';
import { Link } from '@components/link';
import { PromotionList } from '@components/offer/promotion-list';
import { OrderItemList } from '@components/order-item-list';
import { OrderSettingsSummary } from '@components/order-settings-summary';
import { OrderTotals } from '@components/order-totals';
import { P } from '@components/typography';
import { UpsellModal } from '@components/upsell-modal';
import { breakpoints, colors, navigation } from '@constants';
import { useBrazeUpsellContent } from '@hooks/use-braze-upsell-content';
import { useFeature } from '@hooks/use-feature';
import { useUpsellModalHandler } from '@hooks/use-upsell-modal-handler';
import { createNavId } from '@utils/createNavIds';
import { Divider } from '@components/divider';
import { formatSubCartsForProducts } from '@nandosaus/state-management/lib/util/analytics';
import { CartAlert } from './cart-alert';
import { CartPreviewHeader } from './cart-preview-header';
import { DeliveryDiscountCta } from './delivery-discount-cta';

const Wrapper = styled(Flex)`
  background-image: url(/static/images/background.svg);
  background-size: cover;

  min-width: 320px;
  max-width: 480px;

  background-color: ${({ theme }) => theme.colors.grey100};
  @media (min-width: ${breakpoints.md}) {
    width: 480px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const Overflow = styled(Flex)`
  overflow-y: auto;
`;

const Footer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  padding-bottom: max(12px, env(safe-area-inset-bottom));
`;

const CartPreviewContainer = ({ hidePreview, cartPreviewHeading, children }) => {
  return (
    <Wrapper
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      role="region"
      aria-label="Cart Preview"
    >
      <ErrorBoundary>
        <Flex flexDirection="column">
          <CartPreviewHeader hidePreview={hidePreview} heading={cartPreviewHeading} />
        </Flex>
        {children}
      </ErrorBoundary>
    </Wrapper>
  );
};

CartPreviewContainer.propTypes = {
  hidePreview: PropTypes.bool.isRequired,
  cartPreviewHeading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const CartPreview = ({ hidePreview, onSettingsClick, enableUpsellModal, onWelcomePage }) => {
  const {
    CartStore,
    MemberStore,
    MenuStore,
    OrderStore,
    OrderingContextStore,
    DeliveryStore,
    GroupOrderStore,
  } = useRootStore();
  const router = useRouter();
  const deliveryDisclaimerEnabled = useFeature('delivery-disclaimer');
  const cartRemovalEnabled = useFeature('remove-from-cart');

  const emptyCart = CartStore.isEmpty && !CartStore.anythingToUndo;

  const { heading, cta, recommendedProducts, newProductIds, brazeLogImpression, brazeLogClick } = useBrazeUpsellContent(
    {
      heading: 'Got it!',
      cta: 'PROCEED TO CHECKOUT',
    }
  );

  const {
    isUpsellModalEnabled,
    isUpsellModalOpen,
    hasUpsellModalShown,
    closeUpsellModal,
    openUpsellModal,
  } = useUpsellModalHandler();

  useEffect(() => {
    analytics.track(ANALYTICS_EVENTS.CART_VIEWED, {
      products: formatSubCartsForProducts(CartStore.subCarts, {
        orderType: CartStore.orderType,
        group: GroupOrderStore.group,
      }),
      total: CartStore.totalPrices.dollarValue,
    });
  }, [CartStore, GroupOrderStore]);

  const isShowingBrowseMenuButton = router.pathname !== navigation.MENU;

  const getActionButtonTitle = () => {
    if (MenuStore.loading) {
      return 'Loading menu';
    }
    if (DeliveryStore.loading) {
      return 'Estimating delivery';
    }
    if (CartStore.loading) {
      return 'Validating order';
    }
    if (isShowingBrowseMenuButton) {
      return 'Continue';
    }

    return 'Continue to checkout';
  };

  const onCheckoutClick = async () => {
    if (onWelcomePage) {
      analytics.track(ANALYTICS_EVENTS.WELCOME_PAGE_CHECKOUT_CLICKED);
    }

    if (enableUpsellModal && isUpsellModalEnabled && !hasUpsellModalShown) {
      openUpsellModal();
    } else {
      // CartStore is handling errors
      try {
        const { error } = await CartStore.validateOrder();
        if (error) {
          return;
        }
      } catch (err) {
        return;
      }
      router.push(navigation.CHECKOUT);
    }
  };

  const shortestDeliveryEstimate = DeliveryStore.shortestDeliveryTime;
  const isDiscountActive = shortestDeliveryEstimate?.isDiscountActive;
  const isDiscountApplied = shortestDeliveryEstimate?.isDiscountApplied;
  const deliverySponsor = shortestDeliveryEstimate?.sponsor;

  const cartPreviewHeading = GroupOrderStore.isInGroup ? GroupOrderStore.name : 'Your order';

  if (cartRemovalEnabled && CartStore.isCartRemoved) {
    return (
      <CartPreviewContainer hidePreview={hidePreview} heading={cartPreviewHeading}>
        <DefaultState
          title="All items have been removed from your order"
          subtitle="You can undo this change and get your items back."
          onUndo={CartStore.undoRemoveAllSubCarts}
        />
      </CartPreviewContainer>
    );
  }

  if (emptyCart) {
    return (
      <CartPreviewContainer hidePreview={hidePreview} heading={cartPreviewHeading}>
        <DefaultState title="You haven’t added anything to your cart!" subtitle="Items you add will appear here" />
      </CartPreviewContainer>
    );
  }

  return (
    <Wrapper
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      role="region"
      aria-label="Cart Preview"
    >
      <ErrorBoundary>
        <Flex flexDirection="column">
          <CartPreviewHeader hidePreview={hidePreview} heading={cartPreviewHeading} />
        </Flex>
        <>
          <CartAlert
            errors={OrderStore.allErrors}
            hasNetworkError={CartStore.hasNetworkError}
            restaurant={OrderStore.restaurant}
            isSignedIn={MemberStore.isSignedIn}
            isGuestOrderingAvailable={CartStore.isGuestOrderingAvailable}
          />

          <Overflow flex={1} flexDirection="column">
            <Flex mb="1rem" mx="1rem" flexDirection="column" backgroundColor={colors.white} borderRadius="5px">
              <Flex pt="1.5rem" pb="1rem" px="1rem" borderBottom={`1px solid ${colors.grey100}`}>
                <OrderSettingsSummary
                  isCompact
                  onClick={() => {
                    analytics.track(ANALYTICS_EVENTS.ORDER_SETTINGS_OPENED, { location: 'Banner' });
                    onSettingsClick();
                  }}
                />
              </Flex>
              {GroupOrderStore.isInGroup && GroupOrderStore.isHost && (
                <Flex py="1rem" px="1rem" borderBottom={`1px solid ${colors.grey100}`}>
                  <Icon name="card" fill="black" mr="1rem" width="18px" height="18px" />
                  <P variant={4}>You’re paying for this group order</P>
                </Flex>
              )}
              <Flex px="1rem" pt={1} flexDirection="column">
                {GroupOrderStore.isHost ? (
                  <GroupedItemList subCarts={CartStore.subCartsAsArray} />
                ) : (
                  <OrderItemList orderItems={CartStore.orderItems} />
                )}

                <Divider width="100%" my="1rem" />

                {cartRemovalEnabled && (
                  <Flex width="100%" pb="1rem" px="1rem">
                    <Button
                      disabled={CartStore.loading}
                      onClick={CartStore.removeAllSubCarts}
                      width="100%"
                      variant="tertiary"
                    >
                      Clear Order
                    </Button>
                  </Flex>
                )}

                {deliveryDisclaimerEnabled && OrderStore.isDelivery && (
                  <P variant={4} color="greyPrimary">
                    Our menu items cost more for Delivery. Dine-in with us or stop by for a cheeky takeaway to dodge
                    these extra costs.
                  </P>
                )}
              </Flex>
            </Flex>
            <DeliveryDiscountCta
              isDelivery={OrderingContextStore.isDelivery}
              isDiscountActive={isDiscountActive}
              isDiscountApplied={isDiscountApplied}
              deliverySponsor={deliverySponsor}
              onClick={() => {
                const { categoryNames } = MenuStore;
                const firstCategoryId = createNavId(get(categoryNames, '[0]', null));
                const url = firstCategoryId ? `${navigation.MENU}${firstCategoryId}` : navigation.MENU;

                hidePreview();

                analytics.track(ANALYTICS_EVENTS.PROMOTION_CLICKED, {
                  name: 'Discounted Delivery',
                  location: 'Cart',
                });

                router.push(url);
              }}
            />
            {!GroupOrderStore.isGroupInvitee && (
              <PromotionList analyticsLocation="Cart Preview" isCheckoutOfferVariant />
            )}

            <Box mx="1rem" pb={2}>
              {GroupOrderStore.isGroupInvitee && (
                <P color="grey500" variant={4} textAlign="center" fontWeight="bold">
                  PERi-Perks offers aren&apos;t redeemable on group orders
                </P>
              )}
              <OrderTotals
                deliveryCost={OrderStore.formattedDeliveryCost}
                discountPrices={CartStore.discountPrices}
                subtotalPrices={CartStore.subtotalPrices}
                totalPrices={OrderStore.payableTotal}
                surcharges={CartStore.surcharges}
                isDiscountApplied={isDiscountApplied}
                deliverySponsor={deliverySponsor}
                smallTotal
              />
              {isDiscountActive && OrderingContextStore.isDelivery && (
                <P mt="0.5rem" color="grey500" variant={4} textAlign="center">
                  Free Delivery {deliverySponsor ? `by ${deliverySponsor.name}` : ''} is subjected to the following{' '}
                  <StyledLink target="_blank" variant={4} href={navigation.TERMS_CONDITIONS}>
                    terms and conditions
                  </StyledLink>
                </P>
              )}
              {GroupOrderStore.isGroupInvitee && (
                <P color="grey500" variant={4} textAlign="center" fontWeight="bold">
                  Only the person paying for the order will earn PERi-PERKS
                </P>
              )}
            </Box>
          </Overflow>
        </>

        <Footer flexDirection="column" justifyContent="flexEnd" p="12px 1.5rem 0">
          <Flex pt="2px" pb="12px" width="100%" flexDirection="row" justifyContent="space-between">
            <Flex>
              <P variant={3}>Total</P>
            </Flex>
            <Flex>
              <Flex mr="12px">
                <P variant={3}>{OrderStore.payableTotal.formattedPrice}</P>
              </Flex>
              <Flex color="grey500">
                <P variant={3}>{OrderStore.kilojoulesTotal}</P>
              </Flex>
            </Flex>
          </Flex>

          <Flex flexDirection="row" justifyContent="space-between" gap={1}>
            {isShowingBrowseMenuButton && (
              <Button role="button" px={0} variant="actionSecondary" href={navigation.MENU}>
                Browse the Menu
              </Button>
            )}
            <Button px={0} variant="actionPrimary" disabled={!CartStore.allowCheckout} onClick={onCheckoutClick}>
              {getActionButtonTitle()}
            </Button>
          </Flex>
        </Footer>
      </ErrorBoundary>

      {enableUpsellModal && !hasUpsellModalShown && (
        <UpsellModal
          modalOpen={isUpsellModalOpen}
          handleModalClose={closeUpsellModal}
          recommendedProducts={recommendedProducts}
          newProductIds={newProductIds}
          content={{ heading, cta }}
          brazeLogImpression={brazeLogImpression}
          brazeLogClick={brazeLogClick}
        />
      )}
    </Wrapper>
  );
};

CartPreview.propTypes = {
  hidePreview: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  enableUpsellModal: PropTypes.bool,
  onWelcomePage: PropTypes.bool,
};

CartPreview.defaultProps = {
  enableUpsellModal: false,
  onWelcomePage: false,
};

const ComposedCartPreview = observer(CartPreview);
export { ComposedCartPreview as CartPreview };
