import { get, isEmpty } from 'lodash';
import { useRootStore } from '@nandosaus/state-management';

const useBrazeUpsellContent = ({ heading, cta }) => {
  const { BrazeStore, MenuStore } = useRootStore();
  const { upsellCard } = BrazeStore;
  if (!upsellCard || isEmpty(upsellCard.products)) {
    const { menu } = MenuStore;
    const menuRecommendedProducts = get(menu, 'recommendedProducts', []);

    return {
      newProductIds: [],
      recommendedProducts: menuRecommendedProducts,
      heading,
      cta,
    };
  }

  return {
    newProductIds: upsellCard?.newProducts,
    recommendedProducts: upsellCard.products,
    heading: get(upsellCard, 'heading', null),
    cta: get(upsellCard, 'cta', null),
    brazeLogImpression: upsellCard?.brazeLogImpression,
    brazeLogClick: itemPlu => {
      if (upsellCard.plus?.includes(itemPlu)) {
        upsellCard.brazeLogClick();
      }
    },
  };
};

export { useBrazeUpsellContent };
