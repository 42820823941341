import { ANALYTICS_EVENTS, UPSELL_ITEM_MAX_QUANTITY } from '@nandosaus/constants';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { analytics } from '@analytics';
import { MenuItemCardSkeleton } from '@components/card/menu-item-card-skeleton';
import { Box, Flex } from '../grid';
import { Button } from '../button';
import { P } from '../typography';
import { Modal } from '../modal';
import { UpsellItemCard } from '../card/upsell-item-card';
import { OrderAtTableCarousel } from '../carousel/order-at-table-carousel';
import { UpsellModalHeader } from './upsell-modal-header';

const ANALYTICS_LOCATION = 'Upsell Modal';

const UpsellModal = ({
  modalOpen,
  handleModalClose,
  recommendedProducts,
  newProductIds,
  content,
  brazeLogImpression,
  brazeLogClick,
}) => {
  const { CartStore, MenuStore, DeliveryStore } = useRootStore();
  const [productQuantities, setProductQuantities] = useState({});
  const { heading, cta } = content;
  const { hasReordered } = CartStore;

  const { loading } = MenuStore;

  const { shortestDeliveryTime } = DeliveryStore;

  const closeModal = () => {
    analytics.track(
      hasReordered ? ANALYTICS_EVENTS.UPSELL_MODAL_CLOSED_AFTER_REORDER : ANALYTICS_EVENTS.UPSELL_MODAL_CLOSED
    );
    handleModalClose();
  };

  const incrementItem = product => {
    const selectedProductQuantity = productQuantities[product.plu];
    if (!selectedProductQuantity || selectedProductQuantity.quantity >= UPSELL_ITEM_MAX_QUANTITY) {
      return null;
    }

    return setProductQuantities({ ...productQuantities, [product.plu]: selectedProductQuantity + 1 });
  };

  const addItem = product => {
    const selectedProductQuantity = productQuantities[product.plu];
    if (selectedProductQuantity) {
      return incrementItem(product);
    }

    brazeLogClick?.(product.plu);

    analytics.track(
      hasReordered ? ANALYTICS_EVENTS.UPSELL_ITEM_ADDED_AFTER_REORDER : ANALYTICS_EVENTS.UPSELL_ITEM_ADDED,
      {
        product_id: product.plu,
        name: product.name,
        category: product.productCategory?.name,
      }
    );

    return setProductQuantities({ ...productQuantities, [product.plu]: 1 });
  };

  const decrementItem = product => {
    const selectedProductQuantity = productQuantities[product.plu];
    if (!selectedProductQuantity || selectedProductQuantity.quantity <= 1) {
      return null;
    }

    return setProductQuantities({ ...productQuantities, [product.plu]: selectedProductQuantity - 1 });
  };

  const removeItem = product => {
    const selectedProductQuantity = productQuantities[product.plu];
    if (selectedProductQuantity > 1) {
      return decrementItem(product);
    }

    analytics.track(
      hasReordered ? ANALYTICS_EVENTS.UPSELL_ITEM_REMOVED_AFTER_REORDER : ANALYTICS_EVENTS.UPSELL_ITEM_REMOVED,
      {
        product_id: product.plu,
        name: product.name,
        category: product.productCategory?.name,
      }
    );

    const newProductQuantities = { ...productQuantities };
    delete newProductQuantities[product.plu];
    return setProductQuantities(newProductQuantities);
  };

  const addSelectedItemsToCart = () => {
    const hasSelectedProduct = !isEmpty(productQuantities);
    analytics.track(hasSelectedProduct ? ANALYTICS_EVENTS.PROMOTION_APPLIED : ANALYTICS_EVENTS.UPSELL_MODAL_CLOSED, {
      location: ANALYTICS_LOCATION,
    });

    Object.entries(productQuantities).forEach(([plu, quantity]) => {
      const product = recommendedProducts.find(recommendedProduct => plu === recommendedProduct.plu);
      CartStore.addOrderItem({ product, quantity, isUpsellItem: true });
    });

    handleModalClose();
  };

  useEffect(() => {
    if (!modalOpen) {
      return;
    }

    analytics.track(ANALYTICS_EVENTS.PROMOTION_VIEWED, {
      location: ANALYTICS_LOCATION,
    });

    analytics.track(ANALYTICS_EVENTS.PRODUCT_LIST_VIEWED, {
      category: ANALYTICS_LOCATION,
      products: recommendedProducts.map((product, index) => {
        return {
          product_id: product.plu,
          name: product.name,
          position: index,
        };
      }),
    });
  }, [modalOpen, heading, recommendedProducts]);

  useEffect(() => {
    if (!modalOpen) {
      return;
    }

    brazeLogImpression?.();
  }, [modalOpen, brazeLogImpression]);

  return (
    <Modal
      modalTitle=""
      handleClose={closeModal}
      open={modalOpen}
      isDividerHidden
      backgroundImage="/static/images/background.svg"
      headerBackgroundColor="transparent"
      headerButtonColor="black"
      footerBackgroundColor="transparent"
      buttons={{
        primary: (
          <Button
            isFullWidth
            variant="primary"
            onClick={addSelectedItemsToCart}
            mx={{ _: 0, md: 1 }}
            mb={1}
            data-testid="continue-button"
          >
            {cta}
          </Button>
        ),
      }}
      buttonContainerStyle={{ position: 'relative', mt: 1 }}
      childrenContainerStyle={{ marginBottom: 0 }}
      wrapperStyle={{ justifyContent: 'flex-start' }}
    >
      <Box position="relative">
        <UpsellModalHeader heading={heading} />
        <Flex flexDirection="row">
          {loading ? (
            <OrderAtTableCarousel>
              <MenuItemCardSkeleton role="menuitem" />
              <MenuItemCardSkeleton role="menuitem" />
              <MenuItemCardSkeleton role="menuitem" />
            </OrderAtTableCarousel>
          ) : (
            <OrderAtTableCarousel px={1}>
              {recommendedProducts.map(product => {
                const selectedProductQuantity = productQuantities[product.plu];
                const isNew = newProductIds.includes(product.plu);

                const quantityInCart = parseInt(CartStore.productQuantityInCart(product.id), 10);
                const isItemInCart = quantityInCart > 0;
                const isDiscountApplied = shortestDeliveryTime?.isDiscountApplied;
                return (
                  <UpsellItemCard
                    key={product.plu}
                    item={{
                      ...product,
                      categoryName: product.productCategory?.name,
                      formattedDietaryPreferencesList: product.formattedDietaryPreferencesList,
                      formattedKilojoules: product.formattedKilojoules,
                      hasOtherSizes: product.hasOtherSizes,
                      hasVariablePrice: product.hasVariablePrice,
                      isAvailable: product.isAnySizeAvailable,
                      imageAlt: product.name,
                      isEligibleForDeliveryDiscount: product.isEligibleForDeliveryDiscount,
                      plu: product.plu,
                      key: product.plu,
                    }}
                    addItem={() => {
                      addItem(product);

                      analytics.track(ANALYTICS_EVENTS.PROMOTION_CLICKED, {
                        location: ANALYTICS_LOCATION,
                      });
                    }}
                    removeItem={() => removeItem(product)}
                    isNew={isNew}
                    isItemInCart={isItemInCart}
                    isDiscountApplied={isDiscountApplied}
                    quantitySelected={selectedProductQuantity ?? 0}
                  />
                );
              })}
            </OrderAtTableCarousel>
          )}
        </Flex>
        <Box textAlign="center" zIndex="10" position="relative" mt={1}>
          <P variant={2} hideTramlines>
            You can always edit your order before you confirm{' '}
          </P>
        </Box>
      </Box>
    </Modal>
  );
};

UpsellModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  recommendedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      plu: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      prices: PropTypes.shape({
        formattedPrice: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
  newProductIds: PropTypes.arrayOf(PropTypes.string),
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
  }),
  brazeLogImpression: PropTypes.func,
  brazeLogClick: PropTypes.func,
};

UpsellModal.defaultProps = {
  newProductIds: [],
  content: {
    heading: 'Got it!',
    cta: 'proceed to checkout',
  },
  brazeLogImpression: null,
  brazeLogClick: null,
};

const ConnectedUpsellModal = observer(UpsellModal);

export { ConnectedUpsellModal as UpsellModal };
